<template>
  <div class="ParkingSpaceNewxg">
    <div class="cnt-body">
      <div class="ParkingSpace-title">
        <div class="ParkingSpace-title-left">
          <div class="inputs">
            <el-select
              v-model="city"
              filterable
              clearable
              @change="onChangeCity()"
              placeholder="请选择城市仓"
            >
              <el-option
                v-for="item in cityStoreList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="inputs">
            <el-select
              v-model="self"
              @change="onChangeCity()"
              filterable
              clearable
              placeholder="请选择自提点"
            >
              <el-option
                v-for="item in selfTakeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div style="display: flex; align-items: center">
            供货日期：
            <div class="inputs">
              <el-date-picker
                v-model="datetime"
                @change="timeChange"
                :clearable="false"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择供货日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="ParkingSpace-title-right">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            style="background: #333333; border-color: #333333"
            @click="onHandleRefresh"
          ></el-button>
          <el-button type="success" @click="clear">全部清空</el-button>

          <el-button
            type="primary"
            :disabled="disabledExport"
            @click="exportHandle"
            >导出文件</el-button
          >

          <div style="margin-left: 10px">
            <el-upload
              class="upload-demo"
              ref="upload"
              :action="`${BASE.PRO2}/vehicleLayout/list/import`"
              :headers="headers"
              :data="{ date: datetime }"
              :on-success="handlePreview"
              :on-error="handleRemove"
              :auto-upload="true"
              :show-file-list="false"
              accept=".xlsx,.xls"
              :multiple="false"
            >
              <el-button slot="trigger" type="primary">导入</el-button>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="ParkingSpace-cnt">
        <div
          :class="
            item.name == 'F区'
              ? 'ParkingSpace-cnt-itemf ParkingSpace-cnt-item'
              : 'ParkingSpace-cnt-item'
          "
          v-for="(item, index) in datalist"
          :key="index"
        >
          <div class="item-top">
            <div class="item-top-title">{{ item.name }}</div>
            <div class="item-top-wen">
              <i class="el-icon-delete" @click="clear(item)"></i>
              <span @click="clear(item)">清除</span>
            </div>
          </div>
          <div class="item-cnt" style="display: flex">
            <div style="margin-right: 10px">
              <div
                v-for="(itemchild, index1) in item.child1"
                :key="index1 + 's'"
                class="item-cnt-input"
              >
                <div
                  slot="reference"
                  @click="clcikpopover(itemchild, index, index1)"
                  :class="
                    actives == itemchild.label
                      ? 'popover-item popover-item-bg'
                      : showcss == itemchild.label
                      ? 'popover-item popover-item-boder'
                      : 'popover-item'
                  "
                >
                  <div class="popover-item-title">
                    {{ itemchild.label }}
                  </div>
                  <div class="popover-item-line"></div>
                  <div class="popover-item-cnt">
                    <el-tooltip
                      v-if="itemchild.data.length > 0"
                      class="item"
                      effect="dark"
                      :content="htmlcnt1(itemchild.data)"
                      placement="top-start"
                    >
                      <el-button type="text" style="color: #000"
                        ><div
                          class="htmlcss"
                          v-html="htmlcnt(itemchild.data)"
                        ></div
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                v-for="(itemchild, index1) in item.child2"
                :key="index1 + 's12'"
                class="item-cnt-input"
              >
                <div
                  slot="reference"
                  @click="clcikpopover(itemchild, index, index1 + fold)"
                  :class="
                    actives == itemchild.label
                      ? 'popover-item popover-item-bg'
                      : showcss == itemchild.label
                      ? 'popover-item popover-item-boder'
                      : 'popover-item'
                  "
                >
                  <div class="popover-item-title">
                    {{ itemchild.label }}
                  </div>
                  <div class="popover-item-line"></div>
                  <div class="popover-item-cnt">
                    <el-tooltip
                      v-if="itemchild.data.length > 0"
                      class="item"
                      effect="dark"
                      :content="htmlcnt1(itemchild.data)"
                      placement="top-start"
                    >
                      <el-button type="text" style="color: #000"
                        ><div
                          class="htmlcss"
                          v-html="htmlcnt(itemchild.data)"
                        ></div
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      width="60%"
      center
      @close="inputcnt = ''"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="popover-click-item" v-if="indexs !== ''">
        <div class="popover-click-top">
          <el-input
            placeholder="请输入内容"
            v-model="inputcnt"
            @input="inputchange"
          ></el-input>
        </div>
        <div
          class="popover-click-tag"
          v-if="datalist[indexs].child[indexs1].data.length > 0"
        >
          <div
            style="margin-bottom: 10px"
            v-for="(items, i) in datalist[indexs].child[indexs1].data"
            :key="`s${i}`"
          >
            <el-tag
              closable
              type="warning"
              style="margin-right: 20px"
              @close="onHandleClose(datalist[indexs].child[indexs1], i)"
            >
            {{ items.is_morning == 1 ? "【早市】" : "" }}  {{ items.type == 1 ? "城市仓" : "自提点" }}{{ items.name }}
            </el-tag>
          </div>
        </div>

        <div class="popover-click-radio">
          <el-radio v-model="radio" label="1">城市仓</el-radio>
          <el-radio v-model="radio" label="2">自提点</el-radio>
        </div>

        <div class="popover-click-table">
          <el-table
            :data="inputcnt ? inputtable : popoverdatalist"
            v-loading="loading"
            height="100%"
            :row-class-name="tableRowClassName"
            @cell-click="
              onHandleCellClick($event, datalist[indexs].child[indexs1])
            "
          >
            <el-table-column property="name" label="名称">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.is_morning == 1 ? "【早市】" : "" }}
                  {{ radio == 1 ? "【城市仓】" : "【自提点】"
                  }}{{ scope.row.name }}
                </div>
              </template></el-table-column
            >
            <el-table-column property="name">
              <template slot-scope="scope">
                <div style="color: red">
                  <div v-for="item in scope.row.bind" :key="item.id">
                    {{ fontsizecnt(item) }}
                  </div>
                </div>
              </template></el-table-column
            >
            <el-table-column width="30">
              <template slot-scope="scope">
                <div>
                  <div v-for="(es, i) in itemchildtag" :key="`tag${i}`">
                    <div v-if="es.model_id == scope.row.id && radio == es.type">
                      <i
                        class="el-icon-circle-check"
                        style="color: #b4842f"
                      ></i>
                    </div>
                  </div>
                </div> </template
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closedialog">取 消</el-button>
        <el-button
          type="primary"
          @click="popoverhide(datalist[indexs].child[indexs1])"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { vehicleLayoutlistexport } from "@/api/export/index.js";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { BASE } from "@/api/index.js";
export default {
  name: "ParkingSpaceNewxg",
  data() {
    return {
      BASE,
      headers: "",
      fileList: [],
      options: [],
      self: "",
      city: "",
      datetime: "",
      datetimeCopy: "",
      datalist: [],
      label: "",
      popoverdatalist: [],
      showcss: false,
      radio: "1",
      itemchildtag: [],
      disabledExport: false,
      inputcnt: "",
      inputtable: [],
      cityStoreList: [],
      selfTakeList: [],
      actives: "",
      //选择弹框
      centerDialogVisible: false,
      title: "", //
      indexs: "", //
      indexs1: "", //
      loading: false,
      fold: 0,
    };
  },
  computed: {
    /** 当前时间 */
    currentDate() {
      return this.tool.timestampToTime(new Date()).split(" ")[0];
    },
  },
  watch: {
    radio(val) {
      this.inputcnt = "";
      if (val == "1") {
        this.getvehicleLayoutlogisticslist();
      } else {
        this.getvehicleLayoutdeliverylist();
      }
    },
    datetime(val, old) {
      this.datetimeCopy = old;
    },
  },
  created() {
    this.headers = {
      Authorization: sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : "",
      token: sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : "",
    };
    this.datetime = this.currentDate;
  },
  mounted() {
    this.getvehicleLayoutlist();
    this.getAjaxCityStoreList();
    this.getAjaxSelfTakeList();
  },
  methods: {
    onHandleRefresh() {
      this.onChangeCity();
      this.getvehicleLayoutlist();
    },
    fontsizecnt(e) {
      let str = "";
      if (e.type == 1) {
        str =
          this.radio == 1
            ? `该城市仓已在${e.label}车位`
            : `该自提点所在城市仓已在${e.label}车位`;
        return str;
      } else {
        str =
          this.radio == 1
            ? `该城市仓有自提点在${e.label}车位`
            : `该自提点已在${e.label}车位`;
        return str;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //  console.log(row,'111111111')
      if (row.bind.length > 0) {
        return "warning-row";
      }
    },
    onChangeCity() {
      this.$api.ranking
        .vehicleLayoutsearchlabel({
          logistics_id: this.city,
          delivery_id: this.self,
          date: this.datetime,
        })
        .then((res) => {
          this.actives = res.data.label;
        });
    },
    getAjaxCityStoreList() {
      this.$api.logistics
        .entruckLogisticslist({
          name: "",
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          this.cityStoreList = res.data.data;
        });
    },
    getAjaxSelfTakeList() {
      this.$api.logistics
        .entruckDeliverylist({
          name,
          logistics_id: [],
          page: 1,
          pageSize: 1000,
          from: "qc",
        })
        .then((res) => {
          this.selfTakeList = res.data.data;
        });
    },
    //切换时间查询时间排位图
    timeChange() {
      this.$confirm("是否切换供货日?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.actives = "";
          this.getvehicleLayoutlist();
        })
        .catch(() => {
          this.datetime = this.datetimeCopy;
        });
    },
    //批量导入上传失败回调
    handleRemove(file, fileList) {
      this.$message({
        message: "上传失败",
        type: "success",
      });
      this.getvehicleLayoutlist();
    },
    //批量导入上传成功
    handlePreview(file) {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.getvehicleLayoutlist();
    },
    //弹框输入框模糊查询
    inputchange() {
      // inputcnt
      let arrtable = JSON.parse(JSON.stringify(this.popoverdatalist));
      this.inputtable = arrtable.filter((item) =>
        item.name.includes(this.inputcnt)
      );
      // popoverdatalist
    },

    /** 导出 */
    async exportHandle() {
      this.disabledExport = true;
      this.$message.success("正在导出，请耐心等待");
      try {
        const params = {
          date: this.datetime,
        };

        const res = await vehicleLayoutlistexport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax vehicleLayoutlistexport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    //弹框点击tag关闭
    onHandleClose(item, i) {
      console.log(this.datalist[this.indexs].child[this.indexs1], "333");
      let itemdata = JSON.parse(JSON.stringify(item.data));

      item.data.splice(i, 1);
      // this.datalist[this.indexs].child[this.indexs1].data = item.data;
      console.log(item.data, "现在是什么值", i, itemdata);

      this.itemchildtag = item.data;
      this.datalist[this.indexs].child[this.indexs1].data = item.data;

      // let obj = {
      //   date: this.datetime,
      //   saveData: [{ label: item.label, data: item.data }],
      // };
      // this.$api.ranking.vehicleLayoutstore(obj).then((res) => {
      //   console.log(res, "1111111111111111");
      //   this.$message({
      //     message: "保存成功",
      //     type: "success",
      //   });
      // });
    },
    //页面展示文本
    htmlcnt(e) {
      let str = "";
      e.forEach((el,index) => {
        str =
          el.type == 1
            ? str + `<span ${el.is_morning==1?"class=zao":""}>${el.is_morning==1?"【早市】":""}${el.name}</span>` + ((e.length-1)==index?"": "/")
            : str + `<strong ${el.is_morning==1?"class=zao":""}>${el.is_morning==1?"【早市】":""}${el.name}</strong>` + ((e.length-1)==index?"": "/");
      });
      return str;
    },
    //页面展示文本
    htmlcnt1(e,index) {
      let str = "";
      e.forEach((el) => {
        str = str + `${el.name}` + ((e.length-1)==index?"": "/");
      });
      return str;
    },
    //弹框取消或者直接关闭
    closedialog() {
      this.inputcnt = "";
      this.centerDialogVisible = false;
      this.datalist[this.indexs].child[this.indexs1] = JSON.parse(
        JSON.stringify(this.dialogobj)
      );
      this.onHandleRefresh();
      this.datanew();
    },
    //更新数据
    datanew() {
      let datslite = [];
      let datslite1 = [];

      this.datalist.forEach((el) => {
        if (this.fold >= el.child.length) {
          el.child1 = datslite.splice(0, el.child.length);
          el.child2 = [];
        } else {
          datslite = JSON.parse(JSON.stringify(el.child));
          datslite1 = JSON.parse(JSON.stringify(el.child));
          el.child1 = datslite.splice(0, this.fold);
          el.child2 = datslite1.splice(this.fold, el.child.length);
        }
      });
      console.log(this.datalist, "111111111");
    },
    //弹框关闭在保存
    popoverhide(e) {
      let obj = {
        date: this.datetime,
        saveData: [{ label: e.label, data: e.data }],
      };
      this.loading = true;
      this.$api.ranking
        .vehicleLayoutstore(obj)
        .then((res) => {
          this.inputcnt = "";
          this.centerDialogVisible = false;
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.loading = false;
          this.onHandleRefresh();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //点击弹框表格选择自提点城市仓
    onHandleCellClick(row, e) {
      console.log(row, e, this.inputtable, this.popoverdatalist, "===");
      if (row.bind.length > 0) {
        return;
      }
      let conts = 0;
      e.data.forEach((el) => {
        console.log(el, this.radio, row.id, "el===");
        if (this.radio == 1) {
          if (el.logistics_id == row.id) {
            conts = 1;
          }
        } else {
          if (el.type == 1 && el.model_id == row.logistics_id) {
            conts = 1;
          }
        }
      });
      // if (conts == 1) {
      //   this.$message({
      //     message:
      //       this.radio == 1
      //         ? "已经选择了该城市仓下的自提点"
      //         : "该城市仓已存在不能选择城市仓下的自提点",
      //     type: "warning",
      //   });
      //   return;
      // }

      let arr = JSON.parse(JSON.stringify(e.data));
      let indexs = "";
      arr.forEach((el, index) => {
        // 自提点和城市仓ID 有可能相同
        if (el.model_id == row.id && el.type == this.radio) {
          indexs = index;
        }
      });

      if (indexs || indexs === 0) {
        this.itemchildtag.splice(indexs, 1);
      } else {
        this.itemchildtag.push({
          model_id: row.id,
          name: row.name,
          type: this.radio,
          logistics_id: row.logistics_id ? row.logistics_id : "",
          is_morning:row.is_morning
        });
      }
      this.datalist[this.indexs].child[this.indexs1].data = this.itemchildtag;
      this.datanew();
      this.datalist = JSON.parse(JSON.stringify(this.datalist));
    },
    //点击弹出弹框
    clcikpopover(e, index, index1) {
      console.log(e, index, index1, "1222");
      this.showcss = e.label;
      this.itemchildtag = e.data;
      this.radio = "1";
      this.popoverdatalist = [];
      this.getvehicleLayoutlogisticslist();
      this.centerDialogVisible = true;
      this.indexs = index;
      this.indexs1 = index1;
      this.dialogobj = JSON.parse(JSON.stringify(e));

      this.title = `${this.datalist[index].name}-${e.label}`;
    },
    //城市仓
    getvehicleLayoutlogisticslist() {
      this.loading = true;
      this.$api.ranking
        .vehicleLayoutlogisticslist({
          date: this.datetime,
          label: this.showcss,
        })
        .then((res) => {
          console.log(res, "1111111111111111");
          this.popoverdatalist = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //自提点
    getvehicleLayoutdeliverylist() {
      this.loading = true;
      this.$api.ranking
        .vehicleLayoutdeliverylist({ date: this.datetime, label: this.showcss })
        .then((res) => {
          console.log(res, "22222222222");
          this.popoverdatalist = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    clear(e) {
      let obj = {
        location_flag: "",
        date: this.datetime,
      };
      if (e.key) {
        obj.location_flag = e.key;
      } else {
        obj.location_flag = "all";
      }
      this.$confirm(
        obj.location_flag == "all"
          ? "是否确认全部清空?"
          : `是否确认全部清空${e.key}区`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$api.ranking.vehicleLayoutlistclear(obj).then((res) => {
            if (obj.location_flag == "all") {
              this.actives = "";
              this.city = "";
              this.self = "";
            }
            if (this.actives.includes(obj.location_flag)) {
              this.actives = "";
              this.city = "";
              this.self = "";
            }

            this.$message({
              message: "清除成功",
              type: "success",
            });
            this.getvehicleLayoutlist();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消清除",
          });
        });
    },
    //获取车辆排位图
    getvehicleLayoutlist() {
      this.$api.ranking
        .vehicleLayoutlist({ date: this.datetime })
        .then((res) => {
          let datslite = [];
          let datslite1 = [];
          this.fold = res.data.fold;
          res.data.data.forEach((el) => {
            datslite = JSON.parse(JSON.stringify(el.child));
            datslite1 = JSON.parse(JSON.stringify(el.child));
            if (res.data.fold >= el.child.length) {
              el.child1 = datslite.splice(0, el.child.length);
              el.child2 = [];
            } else {
              el.child1 = datslite.splice(0, res.data.fold);
              el.child2 = datslite1.splice(res.data.fold, el.child.length);
            }
          });
          this.datalist = res.data.data;

          console.log(this.datalist, "获取车辆排位图列表");
        });
    },
  },
};
</script>

<style lang="scss">
.ParkingSpaceNewxg {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  .el-table .warning-row {
    background: oldlace;
  }
  .cnt-body {
    height: 100%;
    width: 100%;
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .ParkingSpace-title {
    display: flex;
    margin-bottom: 20px;
    &-left {
      flex: 1;
      display: flex;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    &-right {
    }
  }
  .ParkingSpace-cnt {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    &-item {
      height: 100%;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      .item-top {
        display: flex;
        align-items: center;

        .item-top-title {
          width: 62px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(180deg, #eaf7ff 0%, #ffffff 100%);
          border-radius: 10px 10px 0px 0px;
          border: 1px solid #eeeeee;
          padding-bottom: 10px;
          box-sizing: border-box;
          font-weight: bold;
          font-size: 16px;
          color: #2052af;
        }
        .item-top-wen {
          flex: 1;
          text-align: right;
          font-weight: 400;
          font-size: 14px;
          color: #7f7f7f;
          padding-bottom: 10px;
          box-sizing: border-box;
          padding-right: 10px;
        }
      }
      .item-cnt {
        // width: 556px;
        flex-grow: 1;
        // overflow-y: auto;
        background: linear-gradient(180deg, #eaf7ff 8%, #ffffff 100%);
        border-radius: 10px;
        border: 1px solid #eeeeee;
        margin-top: -10px;
        padding: 12px 11px;
        padding-right: 22px;
        box-sizing: border-box;
        // .el-input {
        //   // background-color: #000;//可以切换背景色
        //   border-radius: 3px;
        // }
        // .el-input-group__prepend {
        //   // background-color: rgba(255, 255, 255, 0); /* 白色背景，50% 透明度 */
        //   background-color: #fff;
        //   font-weight: bold;
        //   font-size: 16px;
        //   color: #2052af;
        // }
        // .el-input__inner {
        //   // background-color: rgba(255, 255, 255, 0); /* 白色背景，50% 透明度 */
        // }
        // .el-input__inner:focus {
        //   border-color: #2052af;
        // }
        .item-cnt-input {
          margin-bottom: 10px;
          position: relative;
          .input-label {
            position: absolute;
            width: 247px;
            border-radius: 0px 0px 10px 10px;
            border: 1px solid #8fb3f5;
            // height: 82px;
            background: #ffffff;
            bottom: -15px;
            left: 0;
            z-index: 99;
          }
        }
      }

      .popover-item {
        width: 258px;
        height: 36px;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #eeeeee;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .popover-item-title {
          font-weight: bold;
          font-size: 16px;
          color: #2052af;
          padding: 0 16px;
          box-sizing: border-box;
        }
        .popover-item-line {
          width: 1px;
          height: 24px;
          background: linear-gradient(
            0deg,
            #ffffff 0%,
            #898989 50%,
            #ffffff 100%
          );
          margin-right: 16px;
        }
        .popover-item-cnt {
          flex: 1;

          white-space: nowrap; /* 确保div保持在同一行 */
          overflow: hidden; /* 隐藏溢出的内容 */
          //

          .htmlcss {
            width: 100%;
            overflow: hidden; /* 隐藏溢出的内容 */
            text-overflow: ellipsis; /* 溢出的文本显示为省略号 */
            white-space: nowrap; /* 不换行 */
            strong {
              color: #2052af;
            }
            .zao {
              color: red !important;
            }
          }
        }
        //         .popover-item-cnt:hover {
        //   width: auto; /* 鼠标移入时显示全部内容 */
        //   overflow: visible; /* 不再隐藏内容 */
        //   white-space: normal; /* 正常换行 */
        //   text-overflow: clip; /* 不显示省略号 */
        // }
      }

      .popover-item-boder {
        border: 1px solid #8fb3f5;
      }
      .popover-item-bg {
        background-color: #2052af;
        color: #fff !important;
        .popover-item-title {
          color: #fff;
        }
        .htmlcss {
          color: #fff !important;
          strong {
            color: #fff !important;
          }
        }
      }
    }
    .ParkingSpace-cnt-itemf {
      .item-top {
        .item-top-title {
          background: linear-gradient(180deg, #fff6e1 0%, #ffffff 100%);

          color: #af7016;
        }
      }
      .item-cnt {
        background: linear-gradient(180deg, #fff6e1 9%, #ffffff 100%);

        .el-input-group__prepend {
          color: #af7016;
        }
        .el-input__inner {
          // background-color: rgba(255, 255, 255, 0); /* 白色背景，50% 透明度 */
        }
        .el-input__inner:focus {
          border-color: #af7016;
        }
      }
    }
  }
}
.popover-click-item {
  display: flex;
  flex-direction: column;
  // width: 330px;
  height: 500px;
  overflow-y: auto;
  .popover-click-tag {
    margin: 10px 0;

    display: flex;
    gap: 10;
    flex-wrap: wrap;
  }

  .popover-click-radio {
    margin: 10px 0;
  }

  .popover-click-table {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }
}
.ParkingSpace-title-right {
  display: flex;
}
</style>
